var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "编码规则" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("div", { staticClass: "tableBtn" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v("新增 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            size: "mini",
                            disabled: _vm.multiple,
                          },
                          on: { click: _vm.handleDelete },
                        },
                        [_vm._v("删除 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "marR10",
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.refresh($event)
                            },
                          },
                        },
                        [_vm._v("刷新 ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      "row-key": "lineId",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                      height: _vm.tableHeight,
                      "max-height": _vm.tableHeight,
                      "select-on-indeterminate": true,
                      "default-sort": { prop: "sortNo", order: "ascending" },
                    },
                    on: {
                      select: _vm.select,
                      "select-all": _vm.selectAll,
                      "selection-change": _vm.handleSelectionChange,
                      "sort-change": _vm.handleSortChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "selection",
                        width: "55",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单据名称",
                        "show-overflow-tooltip": "",
                        prop: "billName",
                        width: "250",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(scope.row.billName) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "billType",
                        label: "单据编号",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "编码规则",
                        "show-overflow-tooltip": "",
                        prop: "codeRuleTable",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "codeRuleDate",
                        label: "编码日期",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "编码方式" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("dict-tag", {
                                attrs: {
                                  options:
                                    _vm.dict.type.coding_rules_encoding_method,
                                  value: scope.row.codeMode,
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "codeRulePrefix",
                        label: "编码前缀",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        prop: "guideNoLength",
                        label: "流水号位数",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "100",
                        prop: "sortNo",
                        label: "排序",
                        "show-overflow-tooltip": "",
                        sortable: true,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 1000, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.codeForm,
                      rules: _vm.rules,
                      "label-width": "90px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "deptId",
                        attrs: { label: "上级目录", prop: "deptId" },
                      },
                      [
                        _c("treeselect", {
                          staticClass: "treeselectWidth",
                          class: { codeTreeselect: _vm.isActive === true },
                          attrs: {
                            "max-depth": 1,
                            options: _vm.treeData,
                            "show-count": true,
                            placeholder: "请选择上级目录",
                            normalizer: _vm.normalizer,
                            disabled: _vm.disabledUp,
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.codeForm.parentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.codeForm, "parentId", $$v)
                            },
                            expression: "codeForm.parentId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f marT15" },
                      [
                        _vm.disabledTwo
                          ? _c(
                              "el-form-item",
                              {
                                key: "billName",
                                attrs: { label: "单据名称", prop: "billName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入单据名称",
                                    maxlength: "8",
                                  },
                                  model: {
                                    value: _vm.codeForm.billName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.codeForm, "billName", $$v)
                                    },
                                    expression: "codeForm.billName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                key: "billName",
                                attrs: { label: "单据名称", prop: "billName" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择单据名称",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.codeChange(
                                          _vm.dict.type.bill_type
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.codeForm.billName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.codeForm, "billName", $$v)
                                      },
                                      expression: "codeForm.billName",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.bill_type,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                        _vm.disabled || _vm.disabledTwo
                          ? _c(
                              "el-form-item",
                              {
                                key: "billType",
                                attrs: { label: "单据编号", prop: "billType" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入数值类型的编号",
                                    maxlength: "18",
                                  },
                                  model: {
                                    value: _vm.codeForm.billType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.codeForm, "billType", $$v)
                                    },
                                    expression: "codeForm.billType",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                key: "billType",
                                attrs: { label: "单据编号", prop: "billType" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: true,
                                    placeholder: "单据编号",
                                  },
                                  model: {
                                    value: _vm.codeForm.billType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.codeForm, "billType", $$v)
                                    },
                                    expression: "codeForm.billType",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "el-form-item",
                          {
                            key: "sortNo",
                            attrs: { label: "规则排序", prop: "sortNo" },
                          },
                          [
                            _c("el-input-number", {
                              attrs: { "controls-position": "right", min: 1 },
                              model: {
                                value: _vm.codeForm.sortNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.codeForm, "sortNo", $$v)
                                },
                                expression: "codeForm.sortNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.disabled && !_vm.disabledTwo
                      ? _c(
                          "div",
                          { staticClass: "x-f marT15" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "前缀类型",
                                  prop: "codeRulePrefix",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "prefixTypeWidth",
                                        attrs: {
                                          placeholder: "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.codeForm.codeRulePrefixType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.codeForm,
                                              "codeRulePrefixType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "codeForm.codeRulePrefixType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "常量", value: 0 },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "固定字段",
                                            value: 1,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("el-input", {
                                      staticClass: "prefixWidth",
                                      attrs: {
                                        maxlength: "4",
                                        placeholder:
                                          _vm.codeForm.codeRulePrefixType === 1
                                            ? "固定字段6位"
                                            : "编码前缀",
                                        disabled:
                                          _vm.disabled ||
                                          _vm.codeForm.codeRulePrefixType === 1,
                                      },
                                      model: {
                                        value: _vm.codeForm.codeRulePrefix,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.codeForm,
                                            "codeRulePrefix",
                                            $$v
                                          )
                                        },
                                        expression: "codeForm.codeRulePrefix",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "日期" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "dateWidth",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "编码日期",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.codeForm.codeRuleDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.codeForm,
                                          "codeRuleDate",
                                          $$v
                                        )
                                      },
                                      expression: "codeForm.codeRuleDate",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "yyyyMMdd",
                                        value: "yyyyMMdd",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "yyyyMM",
                                        value: "yyyyMM",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "MMdd", value: "MMdd" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "yy", value: "yy" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "yyyy", value: "yyyy" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "yymm", value: "yymm" },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "yymmdd",
                                        value: "yymmdd",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "mmyy", value: "mmyy" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "(空)", value: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                key: "guideNoLength",
                                attrs: {
                                  label: "流水位数",
                                  prop: "guideNoLength",
                                },
                              },
                              [
                                _c(
                                  "el-input-number",
                                  {
                                    ref: "refNumber",
                                    staticClass: "inputNumber",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      "controls-position": "right",
                                      min: 2,
                                      max: _vm.guideNoLength,
                                    },
                                    model: {
                                      value: _vm.codeForm.guideNoLength,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.codeForm,
                                          "guideNoLength",
                                          $$v
                                        )
                                      },
                                      expression: "codeForm.guideNoLength",
                                    },
                                  },
                                  [_vm._v(" > ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.disabled && !_vm.disabledTwo
                      ? _c(
                          "div",
                          { staticClass: "x-f marT15" },
                          [
                            _c(
                              "el-form-item",
                              {
                                key: "codeMode",
                                attrs: { label: "编码方式", prop: "codeMode" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "请选择编码方式",
                                    },
                                    model: {
                                      value: _vm.codeForm.codeMode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.codeForm, "codeMode", $$v)
                                      },
                                      expression: "codeForm.codeMode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.coding_rules_encoding_method,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: parseInt(dict.value),
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { key: "codeRule", attrs: { label: "编码规则" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: true,
                                    placeholder: "编码规则",
                                  },
                                  model: {
                                    value: _vm.codeRule,
                                    callback: function ($$v) {
                                      _vm.codeRule = $$v
                                    },
                                    expression: "codeRule",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }