
import domtoimage from "@/views/system/custom/printTemplate/detail/dom-to-image.js";

const Print = class {
    constructor() {
        this.loading = null
        this.width = 0
        this.height = 0
    }
    showLoading() {
        try {
            this.loading = this.$loading({
                lock: true,
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
                customClass: "topLoading",
            });
        } catch (error) {

        }
    }
    hideLoading() {
        try {
            this.loading.close()
        } catch (error) {

        }
    }
    setWith(element) {
        this.width = element.offsetWidth;
        this.height = element.offsetHeight;
        return { width: this.width, height: this.height }
    }
    getImg({ element, direction }) {
        return new Promise(async (resolve, reject) => {
            const { width, height } = this.setWith(element)
            const dataUrl = await domtoimage.toPng(element, {
                height,
                width,
                style: { margin: 0, },
            });
            if (direction === 2) {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                // 设置画布的大小（宽高比为 1:1）
                canvas.width = height;
                canvas.height = width;
                const img = new Image();
                img.src = dataUrl;
                img.onload = () => {
                    context.save();
                    context.translate(canvas.width / 2, canvas.height / 2); // 设置旋转中心为画布中心
                    context.rotate(Math.PI / 2); // 旋转90度
                    context.drawImage(img, -img.width / 2, -img.height / 2); // 将图像绘制到画布上（居中）
                    context.restore();
                    resolve(canvas.toDataURL("image/jpeg", 1.0))
                };
                img.onerror = reject
            } else {
                resolve(dataUrl)
            }
        })
    }
    print() {
        showLoading()
        hideLoading()
    }
}
export default new Print()