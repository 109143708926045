<template>
  <div :class="['text-block', `text-block-${type}`]" @click.stop="$emit('stopClick')">
    <!-- @[] 替换符格式 -->
    <div v-if="type === 1">
      <span>{{ `@[${modelValue}]` }}</span>
      <i v-if="del" class="el-icon-close" @click.stop="$emit('delClick')" />
    </div>
    <!-- 输入框格式 -->
    <div v-else-if="type === 2" class="flex-view">
      <span
        @dblclick="dblclick"
        class="inputText"
        :style="
          showText
            ? ''
            : 'visibility: hidden;  position: fixed;left: -9999999px;top: -9999999px;'
        "
      >
        {{ modelValue }}
      </span>
      <el-input
        ref="input"
        size="mini"
        v-model="value"
        class="custom-input"
        v-if="!showText"
        :style="{ width: inputWidth }"
        @input="input"
        @blur="blur"
      />
      <i v-if="del" class="el-icon-close" @click.stop="$emit('delClick')" />
    </div>
    <!-- 回显文本或输入文本 -->
    <div v-else-if="type === 3">{{ modelValue }}</div>
  </div>
</template>
<script>
export default {
  name: "printTemplateDetail",
  model: { prop: "modelValue", event: "modelValue" },
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: Number,
      default: 1,
    },
    del: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showText: true,
      inputWidth: "30px",
      pointer: {
        show: false,
        inType: 0,
      },
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("modelValue", val);
      },
    },
  },
  methods: {
    async dblclick() {
      await this.input();
      this.showText = false;
      await this.$nextTick();
      this.$refs.input.focus();
    },
    blur() {
      this.showText = true;
      if (!this.value) {
        this.value = "未命名";
      }
    },
    async input() {
      const el = document.querySelector(".inputText");
      await this.$nextTick();
      console.log("el.offsetWidth", el.offsetWidth);
      this.inputWidth = `${el.offsetWidth}px`;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-block {
  height: auto;
  padding: 3px;
  line-height: 1;

  background-color: #ffffff;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */

  .el-icon-close {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  .flex-view {
    display: flex;
    align-items: center;

    .custom-input {
      color: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      font-style: inherit !important;
      font-family: inherit !important;
      ::v-deep .el-input,
      ::v-deep .el-input__inner {
        padding: 0;
        line-height: 1;
        height: auto;
        width: 100%;
        border: none !important;
        color: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        font-style: inherit !important;
        font-family: inherit !important;
      }
      ::v-deep .el-icon-close {
        display: none;
      }
    }
  }
}

.text-block-1,
.text-block-2 {
  border: 1px dashed #ccc;
}
</style>
