<template>
  <!-- 操作公告内容弹窗 -->
  <div class="handleNoticeDialog">
    <c-dialog :title="title" :showDialog.sync="openDialog" :width="800">
      <template #content>
        <div class="marT20" v-loading="loading">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="公告标题" prop="noticeTitle">
                  <el-input size="mini" v-model="form.noticeTitle" placeholder="请输入公告标题" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公告类型" prop="noticeType">
                  <el-select size="mini" v-model="form.noticeType" placeholder="请选择公告类型">
                    <el-option v-for="dict in dict.type.sys_notice_type" :key="dict.value" :label="dict.label"
                      :value="dict.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="内容">
                  <editor v-model="form.noticeContent" :min-height="240" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="sumbmitForm">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import {
  getNoticeDetail,
  addNotice,
  updateNotice
} from '@/api/system/notice'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
  name: 'HandleNoticeDialog',
  components: {
    CDialog
  },
  props: {
    // 弹窗开关
    openDialog: {
      type: Boolean,
      default: false
    },
    curNoticeId: {
      type: String
    }
  },
  dicts: ['sys_notice_type'],
  data() {
    return {
      title: '新增公告',
      loading: false,//遮罩层
      form: {
        noticeId: undefined, //公告id
        noticeTitle: undefined, //公告标题
        noticeType: undefined, //公告类型
        noticeContent: undefined //公告内容(1通知，2公告)
      },
      // 表单校验
      rules: {
        noticeTitle: [
          { required: true, message: '公告标题不能为空', trigger: 'blur' }
        ],
        noticeType: [
          { required: true, message: '公告类型不能为空', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    openDialog: {
      async handler(val) {
        if (val) {
          if (this.curNoticeId) {
            this.loading = true
            this.title = '编辑公告'
            const res = await getNoticeDetail(this.curNoticeId)
            this.form = res.data
            this.loading = false
          } else {
            this.title = '新增公告'
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    //提交表单
    sumbmitForm() {
      this.$refs['form'].validate(async valid => {
        //表单校验
        if (valid) {
          if (this.form.noticeId === undefined) {
            try {
              await addNotice(this.form)
              this.$message.success('新增公告成功')
              this.handleClose()
              this.$emit('getList')
            } catch {
            }
          } else {
            try {
              await updateNotice(this.form)
              this.$message.success('编辑公告成功')
              this.handleClose()
              this.$emit('getList')
            } catch {
            }
          }
        }
      })
    },
    handleClose() {
      this.form = {
        noticeId: undefined, //公告id
        noticeTitle: undefined, //公告标题
        noticeType: undefined, //公告类型
        noticeContent: undefined //公告内容(1通知，2公告)
      }
      this.resetForm('form')
      this.$emit('update:openDialog', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.handleNoticeDialog {
  .el-dialog {
    min-width: 770px;
  }

  ::v-deep .el-dialog__body {
    padding: 0 20px !important;
  }

  .el-input,
  .el-select {
    width: 100%;
  }

  .btn {
    text-align: right;
    padding: 10px 0;
  }
}
</style>
