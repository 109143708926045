var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["text-block", "text-block-" + _vm.type],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$emit("stopClick")
        },
      },
    },
    [
      _vm.type === 1
        ? _c("div", [
            _c("span", [_vm._v(_vm._s("@[" + _vm.modelValue + "]"))]),
            _vm.del
              ? _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("delClick")
                    },
                  },
                })
              : _vm._e(),
          ])
        : _vm.type === 2
        ? _c(
            "div",
            { staticClass: "flex-view" },
            [
              _c(
                "span",
                {
                  staticClass: "inputText",
                  style: _vm.showText
                    ? ""
                    : "visibility: hidden;  position: fixed;left: -9999999px;top: -9999999px;",
                  on: { dblclick: _vm.dblclick },
                },
                [_vm._v(" " + _vm._s(_vm.modelValue) + " ")]
              ),
              !_vm.showText
                ? _c("el-input", {
                    ref: "input",
                    staticClass: "custom-input",
                    style: { width: _vm.inputWidth },
                    attrs: { size: "mini" },
                    on: { input: _vm.input, blur: _vm.blur },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  })
                : _vm._e(),
              _vm.del
                ? _c("i", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("delClick")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm.type === 3
        ? _c("div", [_vm._v(_vm._s(_vm.modelValue))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }