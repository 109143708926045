<template>
  <div class="sliderassembly">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="(items, index) in treeData"
        :title="items.name"
        :name="index + 1"
        :key="index"
      >
        <div
          :class="['custom-tree-node', !item.children ? 'custom-tree-move' : '']"
          draggable
          @dragstart="(e) => dragstart(e, item)"
          @dragend="dragends"
          @dragover.prevent
          v-for="(item, ind) in items.children"
          :key="`${ind}.${loadIndex}`"
        >
          <TextBlock
            v-if="allBlock"
            class="ewmBox"
            :ref="`ewmBox-${item.value}`"
            v-model="allBlock[item.value]"
          />
          <div>
            {{ item.name }}
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import TextBlock from "@/views/system/custom/printTemplate/detail/text-block/index.vue";
export default {
  name: "printTemplateLeft",
  components: { TextBlock },
  props: {
    pointer: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    config: {
      get() {
        return this.pointer;
      },
      set(v) {
        this.$emit("update:pointer", v);
      },
    },
  },
  data() {
    return {
      loadIndex: 1,
      activeNames: [1] /* 侧边栏组件显示 */,
      allBlock: null,
      treeData: [
        {
          name: "参数",
          icon: "el-icon-c-scale-to-original",
          value: "commoditysearch",
          children: this.data,
        },
      ],
    };
  },
  mounted() {
    const obj = {};
    this.treeData[0].children.forEach((x) => {
      obj[x.value] = x.name;
    });
    this.allBlock = obj;
  },
  methods: {
    async dragstart(event, o) {
      if (o?.value) {
        /* 开启穿透 */
        try {
          this.config.show = true;
          const data = {
            value: o.value,
            name: o.value === "input" ? "未命名" : o.name,
            common: o.common,
          };
          event.dataTransfer.setData("component", JSON.stringify(data));
          event.dataTransfer.effectAllowed = "all"; // 设置拖拽效果
          const div =
            this.$refs[`ewmBox-${data.value}`]?.[0]?.$el ||
            this.$refs[`ewmBox-${data.value}`]?.$el;
          event.dataTransfer.setDragImage(div, 0, 0);
        } catch (error) {
          console.log("dragstartdragstart err", error);
        }
      }
    },
    dragends() {
      /* 关闭穿透 */
      this.config.show = false;
      this.loadIndex = this.loadIndex + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
/* 组件 */
.sliderassembly {
  .custom-tree-node {
    width: 100%;
    font-size: 14px;
  }
  .custom-tree-move {
    cursor: move;
  }
  .ewmBox {
    position: fixed;
    left: -9999999px;
    top: -9999999px;
  }
}
</style>
