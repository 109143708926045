var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sliderassembly" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.treeData, function (items, index) {
          return _c(
            "el-collapse-item",
            { key: index, attrs: { title: items.name, name: index + 1 } },
            _vm._l(items.children, function (item, ind) {
              return _c(
                "div",
                {
                  key: ind + "." + _vm.loadIndex,
                  class: [
                    "custom-tree-node",
                    !item.children ? "custom-tree-move" : "",
                  ],
                  attrs: { draggable: "" },
                  on: {
                    dragstart: function (e) {
                      return _vm.dragstart(e, item)
                    },
                    dragend: _vm.dragends,
                    dragover: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm.allBlock
                    ? _c("TextBlock", {
                        ref: "ewmBox-" + item.value,
                        refInFor: true,
                        staticClass: "ewmBox",
                        model: {
                          value: _vm.allBlock[item.value],
                          callback: function ($$v) {
                            _vm.$set(_vm.allBlock, item.value, $$v)
                          },
                          expression: "allBlock[item.value]",
                        },
                      })
                    : _vm._e(),
                  _c("div", [_vm._v(" " + _vm._s(item.name) + " ")]),
                ],
                1
              )
            }),
            0
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }